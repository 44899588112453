import React, {Component} from 'react';
import {Autocomplete, Grid, Paper, styled, TextField} from "@mui/material";



const CssAutocompleteField = styled(Autocomplete)({
    '& label.Mui-focused': {
        fontSize: '2rem',
    },
    '& .MuiInput-underline:after': {
        fontSize: '2rem',
    },
    '& .MuiTextField-root': {
        fontSize: '2rem',
    },'& .MuiInputLabel-root': {
        fontSize: '1.6rem',
    },
    '& .MuiInput-input': {
        height: '2.3rem',
        fontSize: '1.6rem'
    },

})

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        fontSize: '2rem',
    },
    '& .MuiInput-underline:after': {
        fontSize: '2rem',
    },
    '& .MuiTextField-root': {
        fontSize: '2rem',
    },'& .MuiInputLabel-root': {
        fontSize: '1.6rem',
    },
    '& .MuiInput-input': {
        height: '2.3rem',
        fontSize: '1.6rem'
    },


});

class SearchPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search_title : "",
            search_author : "",
            search_attribution : "",
            search_form: "",
            search_transcription: "",
            search_doubt: "",
            search_bibliography: ""
        }
    }
    handleChange = (name) => event => {
        let me = this
        let val = event.target.value

        if (name === "search_form" || name === "search_attribution" ){
            val = event.target.innerText
        }
        if (val === undefined){
            val =""
        }
        this.setState({[name]: val}, () => {
            me.props.callbackToParent(me.state)
        });

    };
    render() {
        return (
            <div >

                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <CssTextField id="titolo" label="Titolo / Incipit" variant="standard" fullWidth={true} onChange={this.handleChange('search_title')} />
                    </Grid>
                    <Grid item xs={3}>

                        <CssTextField id="autore" label="Autore" variant="standard" fullWidth={true} onChange={this.handleChange('search_author')}/>
                    </Grid>
                    <Grid item xs={3}>
                        <CssAutocompleteField
                            disablePortal
                            id="attribuzione"
                            options={this.props.attribuzione_options}
                            // sx={{ width: 300 }}
                            fullWidth={true}
                            PaperComponent={({ children }) => (
                                <Paper style={{ fontSize: "1.4rem" }}>{children}</Paper>
                            )}
                            onChange={this.handleChange('search_attribution')}
                            renderInput={(params) => <TextField variant="standard" {...params} label="Attribuzione" />}
                        />
                        {/*<CssTextField id="attribuzione" label="Attribuzione" variant="standard" fullWidth={true} onChange={this.handleChange('search_attribution')}/>*/}
                    </Grid>
                    <Grid item xs={3}>
                        {/*<CssTextField id="forma" label="Forma" variant="standard" fullWidth={true} onChange={this.handleChange('search_form')}/>*/}
                        <CssAutocompleteField
                            disablePortal
                            id="forma"
                            options={this.props.form_options}
                            // sx={{ width: 300 }}
                            fullWidth={true}
                            PaperComponent={({ children }) => (
                                <Paper style={{ fontSize: "1.4rem" }}>{children}</Paper>
                            )}
                            onChange={this.handleChange('search_form')}
                            renderInput={(params) => <TextField variant="standard" {...params} label="Forma" />}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <CssTextField id="trascrizione" label="Forme" variant="standard" fullWidth={true} onChange={this.handleChange('search_transcription')}/>
                    </Grid>
                    <Grid item xs={4}>
                        <CssTextField id="dubbi" label="Primi dubbi e polemiche sull’autenticità" variant="standard" fullWidth={true} onChange={this.handleChange('search_doubt')}/>
                    </Grid>
                    <Grid item xs={4}>

                        <CssTextField id="biblio_gen" label="Bibliografia generale" variant="standard" fullWidth={true} onChange={this.handleChange('search_bibliography')}/>
                    </Grid>
                </Grid>
            </div>
        );
    }
}


export default SearchPanel;
