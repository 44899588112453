import React, {Component} from 'react';
import {Grid, Typography} from "@mui/material";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import {cleanUpText} from "./Utils";
import bibliografia from "../data/bibliografia.json";
class Autore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            a: this.props.data,
            bio_show :false,
            bibliografia : ""
        }

        // console.log(this.state.a);
    }

    componentDidMount() {
        let biblio = ""
        try {
            this.state.a.bibliografia.forEach(b => {
                biblio += b.bibliografia_titolo + "; "
            })
            biblio = biblio.replaceAll(/ /g," ")
        }catch (e){

        }
        bibliografia.forEach( b =>{
            biblio = biblio.replaceAll(b.key, '<span class="tooltip">'+b.key+'<span class="tooltiptext">'+b.value+'</span></span>')
        })
        this.setState({bibliografia:biblio})

    }

    toggleBio = () =>{
        this.setState({bio_show : !this.state.bio_show})
    }
    render() {
        return (
            <>
                <Grid item xs={12} >
                    <Typography variant="h5" style={{display:"flex"}}>{this.state.a.name} {this.state.a.surname} ({this.state.a.luogo_nascita}, {this.state.a.data_nascita} - {this.state.a.luogo_morte}, {this.state.a.data_morte}) <MenuBookIcon style={{fontSize:"2rem", cursor:"pointer"}} onClick={()=>{this.toggleBio()}}/></Typography>
                </Grid>
                <Grid item xs={12} display={this.state.bio_show ? "block" : "none"}>
                    <Typography>
                        <div dangerouslySetInnerHTML={{__html: cleanUpText(this.state.a.biografia)}} style={{maxHeight:"200px",overflowY:"auto", fontSize:"1.3rem", marginBottom:"10px"}}/>
                        <span dangerouslySetInnerHTML={{__html: cleanUpText(this.state.bibliografia)}} style={{maxHeight:"200px",overflowY:"auto", fontSize:"1.3rem" }} />
                    </Typography>
                </Grid>
                <Grid item xs={12} display={this.state.a.link_esterno1.length > 0 ? "block" : "none"}>
                    <Typography ><div  style={{maxHeight:"200px",overflowY:"auto", fontSize:"1.3rem"}}><a href={this.state.a.link_esterno1} target={"_blank"}>{this.state.a.link_esterno1}</a> </div></Typography>
                </Grid>
            </>
        );
    }
}


export default Autore;
