import React, {Component, createRef} from 'react';
import axios from "axios";
import {Container, Grid, Paper, Typography} from "@mui/material";
import * as _ from "underscore";
// import ReactHtmlParser from 'react-html-parser';

// import MenuBookIcon from '@mui/icons-material/MenuBook';
import Autore from "./Autore";
import {Label} from "@mui/icons-material";
import "./Documento.css"
import "./Tooltip.css"
import Mark from "mark.js";
import {cleanUpText} from "./Utils";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import ImageDialog from "./ImageDialog";

const bibliografia = require("../data/bibliografia.json");


const uuidv4 = require('uuid/v4');

const queryString = require('query-string');

class Documento extends Component {
    constructor(props) {
        super(props);
        let id_fromQuery = (queryString.parse(this.props.location.search)).id
        this.state = {
            id: id_fromQuery,

            titolo: "",
            edizione_scelta: "",
            presentazione: "",
            autori: [],
            tipologia: "",
            forma: "",
            forma_metrica: "",
            provenienza: "",
            prima_sede_publicazione: "",
            fortuna_del_falso: "",
            altri_materiali : "",
            modus_operandi : "",
            primi_dubbi: "",
            moventi_falsificazione: "",
            testo: "",
            immagine: "",
            immagine_content : "",
            notes: [],
            successive_edizioni_falso: [],
            altre_redazioni_falso: [],
            bibliografia_genereale: [],
            refPrimiDubbi: createRef(),
            showImage : false
        }
    }

    componentDidMount() {
        let me = this
        axios.post(window.ArFLISiteUrlPrefix + 'GetDoc', {id_doc: this.state.id}).then(function (response) {
            console.log(response.data);

            let aut = []

            response.data.autori.forEach((a) => {

                aut.push(<Autore data={a}/>)
            })
            let testo = decodeURIComponent(response.data.pagine[0].trascription)
            let image = response.data.pagine[0].image


            let noteText = []
            let notePagina = []
            let notePaginaHtml = ""

            let pWrapper = document.createElement('div');
            pWrapper.innerHTML = testo

            let notes = pWrapper.getElementsByClassName("notes")

            for (let n of Array.from(notes)) {
                if (n.getAttribute("textannotation") !== null) {
                    if (n.getAttribute("textannotation").length > 0 && !noteText.includes(n.getAttribute("textannotation"))) {

                        noteText.push(n.getAttribute("textannotation"))
                        notePagina.push(<li class='footnote' key={uuidv4()}>{n.getAttribute("textannotation")}</li>)
                        notePaginaHtml += "<li>" + n.getAttribute("textannotation") + "</li>"


                    } else {
                        n.removeAttribute("textannotation")
                    }
                }
            }
            // let pTrascription = ReactHtmlParser(pWrapper.innerHTML)

            // console.log(response.data.forma_metrica);

            let needleSearch = sessionStorage.getItem("needleItems")
            needleSearch = JSON.parse(needleSearch);


            let trs = pWrapper.innerHTML


            let fortuna = response.data.fortuna_del_falso.replaceAll(/ò/g, "ò").replaceAll(/ /g, " ")
            let provenienza = response.data.provenienza_presunta
            let primi_dubbi = response.data.primi_dubbi
            let moventi_falsificazione = response.data.moventi_falsificazione
            let modus_operandi = response.data.modus_operandi


            let altri_materiali  = ""
            response.data.altri_materiali.forEach(am =>{
                altri_materiali += "<p>"+am.altro_materiale_titolo+"</p>"
            })




            // let x = document.createElement("div")
            // x.innerHTML = fortuna
            // fortuna = x.textContent
            // fortuna = fortuna.replaceAll(/ /g, " ")
            // console.log(fortuna);


            // console.log(fortuna);
            let bibliogen = ""
            if (response.data.bibliografia_generale !== undefined) {
                try {
                    response.data.bibliografia_generale.forEach(b => {
                        bibliogen += b.bibliografia_titolo + "; "
                    })
                    bibliogen = bibliogen.replaceAll(/ /g, " ")
                } catch (e) {

                }


            }
            bibliografia.forEach(b => {
                fortuna = fortuna.replaceAll(b.key, '<span class="tooltip">' + b.key + '<span class="tooltiptext">' + b.value + '</span></span>')
                altri_materiali = altri_materiali.replaceAll(b.key, '<span class="tooltip">' + b.key + '<span class="tooltiptext">' + b.value + '</span></span>')
                provenienza = provenienza.replaceAll(b.key, '<span class="tooltip">' + b.key + '<span class="tooltiptext">' + b.value + '</span></span>')
                bibliogen = bibliogen.replaceAll(b.key, '<span class="tooltip">' + b.key + '<span class="tooltiptext">' + b.value + '</span></span>')
                primi_dubbi = primi_dubbi.replaceAll(/ /g, " ").replaceAll(b.key, '<span class="tooltip">' + b.key + '<span class="tooltiptext">' + b.value + '</span></span>')
                moventi_falsificazione = moventi_falsificazione.replaceAll(/ /g, " ").replaceAll(b.key, '<span class="tooltip">' + b.key + '<span class="tooltiptext">' + b.value + '</span></span>')
                modus_operandi = modus_operandi.replaceAll(/ /g, " ").replaceAll(b.key, '<span class="tooltip">' + b.key + '<span class="tooltiptext">' + b.value + '</span></span>')
            })

            try {
                bibliogen = bibliogen.substring(0, bibliogen.length-2) + "."
            }catch (e){

            }


            me.setState({
                titolo: response.data.titolo,
                edizione_scelta: response.data.edizione_scelta,
                presentazione: response.data.presentazione_sommaria,
                attribuzione: response.data.attribuzione,
                tipologia: response.data.tipologia,
                forma: response.data.forma,
                forma_metrica: response.data.forma_metrica !== undefined ? "(" + response.data.forma_metrica + ")" : "",
                provenienza: provenienza,
                prima_sede_publicazione: response.data.prima_sede_pubblicazione,
                fortuna_del_falso: fortuna,
                altri_materiali : altri_materiali,
                primi_dubbi: primi_dubbi,
                moventi_falsificazione: moventi_falsificazione,
                descrizione_supporto: response.data.descrizione_supporto,
                supporto: response.data.supporto,
                modus_operandi:modus_operandi,
                testo: trs,
                immagine: image,
                successive_edizioni_falso: response.data.successive_edizioni_falso,
                altre_redazioni_falso: response.data.altre_redazioni_falso,
                autori: aut,
                notes: notePagina,
                bibliografia_genereale: bibliogen
            }, () => {

                if (needleSearch !== null) {
                    console.log(needleSearch);
                    // if (needleSearch.dubbi.trim().length > 0) {
                    //     response.data.primi_dubbi = response.data.primi_dubbi.replaceAll(needleSearch.dubbi, "<span style='background-color: yellow'>" + needleSearch.dubbi + "</span>")
                    // }

                    me.markInstance = new Mark(document.querySelector("#primidubbi"));
                    me.markInstance.unmark({
                        done: () => {
                            me.markInstance.mark(needleSearch.dubbi.trim(), {element: "hilited"});
                        }
                    });


                    me.markInstance2 = new Mark(document.querySelector("#pageBody"));
                    me.markInstance2.unmark({
                        done: () => {
                            me.markInstance2.mark(needleSearch.trascription.trim(), {element: "hilited"});
                        }
                    });


                    me.markInstance3 = new Mark(document.querySelector("#bibliografiaGenerale"));
                    me.markInstance3.unmark({
                        done: () => {
                            me.markInstance3.mark(needleSearch.bibliographyGen.trim(), {element: "hilited"});
                        }
                    });

                    // if (needleSearch.trascription.trim().length > 0) {
                    //     trs = trs.replaceAll(needleSearch.trascription, "<span style='background-color: yellow'>" + needleSearch.trascription + "</span>")
                    // }

                    sessionStorage.removeItem('needleItems');
                }

            })
        })
    }

    getImage = (image) => {
        let me = this
        axios.post(window.ArFLISiteUrlPrefix + 'GetImage', {id_image: image}).then(function (response) {

            me.setState({immagine_content:response.data}, () =>{
                me.setState({showImage:true})
            })

        })
    }
    closeDialog = () =>{
        this.setState({showImage:false})
    }
    render() {
        return (
            <main id="site-content" role="main">
                <article className="post-2 page type-page status-publish has-post-thumbnail hentry" id="post-2" style={{padding: 0}}>
                    <div className="post-inner thin ">
                        <Container>
                            <Paper variant={"outlined"} elevation={2} style={{padding: "8px"}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h3">{this.state.titolo}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>

                                        <Typography variant="h6">Fonte</Typography>
                                        <div dangerouslySetInnerHTML={{__html: cleanUpText(this.state.edizione_scelta)}} style={{overflowY: "auto", fontSize: "13px", height: "101%"}}/>
                                        {/*<Typography variant="subtitle1">{this.state.edizione_scelta}</Typography>*/}
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div dangerouslySetInnerHTML={{__html: cleanUpText(this.state.presentazione)}} style={{overflowY: "auto", fontSize: "13px", height: "101%"}}/>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <div>
                                            <Typography variant="h6">{this.state.autori.length > 1 ? "Autori" : "Autore"}</Typography>
                                            {this.state.autori}
                                        </div>
                                        <div style={{marginTop: "12px"}}>
                                            <Typography variant="h6">Attribuzione</Typography>
                                            <div variant="h5" style={{fontSize: "1.3rem"}}>{this.state.attribuzione}</div>
                                        </div>
                                        <div style={{marginTop: "12px"}}>
                                            <Typography variant="h6">Tipologia</Typography>
                                            <div variant="h5" style={{fontSize: "1.3rem"}}>{this.state.tipologia}</div>
                                        </div>
                                        <div style={{marginTop: "12px"}}>
                                            {this.state.supporto !== null ?
                                                <Grid item xs={12}>
                                                    <Typography variant="h6">Supporto presunto</Typography>
                                                    <div variant="h5" style={{fontSize: "1.3rem"}}>{this.state.supporto}</div>
                                                </Grid>
                                                : ""}
                                        </div>
                                        <div style={{marginTop: "12px"}}>
                                            <Typography variant="h6">Forma Metrica</Typography>
                                            <div>
                                                <span dangerouslySetInnerHTML={{__html: cleanUpText(this.state.forma)}} style={{overflowY: "auto", fontSize: "1.3rem", height: "101%",marginRight:"8px"}} />
                                                <span dangerouslySetInnerHTML={{__html: cleanUpText(this.state.forma_metrica)}} style={{overflowY: "auto", fontSize: "1.3rem", height: "101%"}}/>
                                            </div>
                                            {/*<div variant="h5" style={{ fontSize: "1.3rem"}}>{this.state.forma} {this.state.forma_metrica}</div>*/}
                                        </div>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="h5">
                                            {this.state.immagine.length > 0 ?


                                                    <div className={"divImage"}><PhotoCamera style={{fontSize: "2rem", cursor: "pointer"}} onClick={() => {
                                                        this.getImage(this.state.immagine)
                                                    }}/></div>


                                                : ""}
                                            <div dangerouslySetInnerHTML={{__html: this.state.testo}} id={"pageBody"} style={{overflowY: "auto", fontSize: "1.3rem"}}/>
                                        </Typography>
                                        <div className="footnotes">
                                            <Typography variant="h5"style={{marginBottom:"8px"}}>
                                                <p>Note</p>
                                            </Typography>
                                            <ol  style={{marginTop: "-8px", columnCount: "3"}}>
                                                {this.state.notes}
                                            </ol>
                                        </div>
                                    </Grid>
                                    {this.state.provenienza.length > 0 ?
                                    <Grid item xs={12}>
                                        <Typography variant="h6">Provenienza Presunta</Typography>
                                        <Typography variant="h5">
                                            <span dangerouslySetInnerHTML={{__html: cleanUpText(this.state.provenienza)}} style={{maxHeight: "200px", overflowY: "auto", fontSize: "13px"}}/>
                                        </Typography>
                                    </Grid>
                                        :""}

                                    {this.state.prima_sede_publicazione.length > 0 ?
                                    <Grid item xs={12}>
                                        <Typography variant="h5">
                                            <Typography variant="h6">Prima sede di pubblicazione</Typography>
                                            <div dangerouslySetInnerHTML={{__html: cleanUpText(this.state.prima_sede_publicazione)}} style={{maxHeight: "200px", overflowY: "auto", fontSize: "13px", height: "102%"}}/>
                                            {this.state.successive_edizioni_falso.length > 0 ?
                                                <>
                                                    <Typography variant="h6" style={{marginTop: "12px"}}>Successive edizioni del falso</Typography>
                                                    {this.state.successive_edizioni_falso.map(value => {
                                                        // return <div style={{maxHeight: "200px", overflowY: "auto", fontSize: "1.3rem"}}>{value.edizione_titolo}</div>
                                                        return <div dangerouslySetInnerHTML={{__html: cleanUpText(value.edizione_titolo)}} style={{maxHeight: "200px", overflowY: "auto", fontSize: "13px"}}/>

                                                    })}
                                                </>
                                                : ""}


                                            {this.state.altre_redazioni_falso.length > 0 ?
                                                <>
                                                    <Typography variant="h6" style={{marginTop: "12px"}}>Altre redazioni del falso</Typography>
                                                    {this.state.altre_redazioni_falso.map(value => {
                                                        // return <div style={{maxHeight: "200px", overflowY: "auto", fontSize: "1.3rem"}}>{value.redazione_titolo}</div>
                                                        return <div dangerouslySetInnerHTML={{__html: cleanUpText(value.redazione_titolo)}} style={{maxHeight: "200px", overflowY: "auto", fontSize: "1.3rem"}}/>

                                                    })}
                                                </>
                                                : ""}
                                        </Typography>
                                    </Grid>
                                    :""}
                                    {this.state.altri_materiali.length > 0 ?
                                        <Grid item xs={12}>
                                            <Typography variant="h6" style={{marginTop: "12px"}}>Altri materiali</Typography>
                                            <Typography variant="h5">
                                                <span dangerouslySetInnerHTML={{__html: cleanUpText(this.state.altri_materiali)}} style={{maxHeight: "200px", overflowY: "auto", fontSize: "1.3rem"}}/>
                                            </Typography>
                                        </Grid>
                                        : ""}
                                    <Grid item xs={12} style={{marginTop: "15px"}}>
                                        <Typography variant="h6">Fortuna del falso</Typography>
                                        <Typography variant="h5">
                                            <span dangerouslySetInnerHTML={{__html: cleanUpText(this.state.fortuna_del_falso)}} style={{maxHeight: "200px", overflowY: "auto", fontSize: "1.3rem"}}/>
                                        </Typography>
                                    </Grid>

                                    <Grid ref={this.state.refPrimiDubbi} item xs={6}>
                                        <Typography variant="h6">Primi dubbi e polemiche sull’autenticità</Typography>
                                        <Typography variant="h5">
                                            <span id={"primidubbi"} dangerouslySetInnerHTML={{__html: cleanUpText(this.state.primi_dubbi)}} style={{maxHeight: "500px", fontSize: "13px", height: "101%", overflowY: "auto"}}/>
                                        </Typography>
                                    </Grid>
                                    {this.state.moventi_falsificazione.length > 0 ?
                                        <Grid item xs={6}>
                                            <Typography variant="h6">Moventi e ragioni storiche della falsificazione</Typography>

                                            <Typography variant="h5">
                                                <span dangerouslySetInnerHTML={{__html: cleanUpText(this.state.moventi_falsificazione)}} style={{maxHeight: "500px", overflowY: "auto", fontSize: "13px", height: "101%"}}/>
                                            </Typography>
                                        </Grid>
                                        : ""}
                                    {this.state.modus_operandi.length > 0 ?
                                        <Grid item xs={12} style={{marginTop: "15px"}}>
                                            <Typography variant="h6" marginBottom={"8px"}>Falsario al lavoro (modus operandi)</Typography>
                                            <Typography variant="h5">
                                                <div  dangerouslySetInnerHTML={{__html: cleanUpText(this.state.modus_operandi)}} style={{fontSize: "1.3rem", height: "101%"}}/>
                                            </Typography>
                                        </Grid>
                                        : ""}
                                    {this.state.bibliografia_genereale.length > 0 ?
                                        <Grid item xs={12} style={{marginTop: "15px"}}>
                                            <Typography variant="h6" marginBottom={"8px"}>Bibliografia generale</Typography>
                                            <Typography variant="h5">
                                                {/*<div dangerouslySetInnerHTML={{__html: cleanUpText(this.state.moventi_falsificazione)}} style={{maxHeight: "500px", overflowY: "auto", fontSize: "1.3rem",height:"101%"}}/>*/}

                                                <div id={"bibliografiaGenerale"} dangerouslySetInnerHTML={{__html: cleanUpText(this.state.bibliografia_genereale)}} style={{fontSize: "1.3rem", height: "101%"}}/>
                                            </Typography>
                                        </Grid>
                                        : ""}
                                </Grid>
                            </Paper>
                        </Container>
                    </div>
                </article>
                <ImageDialog open={this.state.showImage} img={this.state.immagine_content} handleDialogClose={() => this.closeDialog()}/>
            </main>
        );
    }
}


export default Documento;
