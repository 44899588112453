import React, {Component} from 'react';

class Header extends Component {
    // constructor(props) {
    //     super(props);
    //
    // }

    render() {
        return (
            <div className={"fixed-header"}>
                <div id={"site-header"}>
                    <div className={"header-inner section-inner"}>
                        <div className={"header-titles-wrapper"}>
                            <div className="header-titles">
                                <div className="site-title faux-heading"><a href="/">Archivio dei Falsi Letterari Italiani (ArFLI)</a></div>
                                <div className="site-description">Italian Literary Forgeries Archive</div>
                            </div>
                        </div>
                        <div className="header-navigation-wrapper">
                            <nav className="primary-menu-wrapper" aria-label="Orizzontale" role="navigation">
                                <ul className="primary-menu reset-list-style">
                                    <li id="menu-item-29" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-29"><a href="#/archivio">Archivio</a></li>
                                    <li id="menu-item-29" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-29"><a href="#/credits">Contatti</a></li>
                                </ul>
                            </nav>

                            {/* <div className="header-toggles hide-no-js">*/}
                            {/*    <div className="toggle-wrapper search-toggle-wrapper">*/}
                            {/*        <button className="toggle search-toggle desktop-search-toggle" data-toggle-target=".search-modal" data-toggle-body-class="showing-search-modal" data-set-focus=".search-modal .search-field" aria-expanded="false">*/}
                            {/*			<span className="toggle-inner">*/}
                            {/*				<svg className="svg-icon" aria-hidden="true" role="img" focusable="false" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23"><path*/}
                            {/*                    d="M38.710696,48.0601792 L43,52.3494831 L41.3494831,54 L37.0601792,49.710696 C35.2632422,51.1481185 32.9839107,52.0076499 30.5038249,52.0076499 C24.7027226,52.0076499 20,47.3049272 20,41.5038249 C20,35.7027226 24.7027226,31 30.5038249,31 C36.3049272,31 41.0076499,35.7027226 41.0076499,41.5038249 C41.0076499,43.9839107 40.1481185,46.2632422 38.710696,48.0601792 Z M36.3875844,47.1716785 C37.8030221,45.7026647 38.6734666,43.7048964 38.6734666,41.5038249 C38.6734666,36.9918565 35.0157934,33.3341833 30.5038249,33.3341833 C25.9918565,33.3341833 22.3341833,36.9918565 22.3341833,41.5038249 C22.3341833,46.0157934 25.9918565,49.6734666 30.5038249,49.6734666 C32.7048964,49.6734666 34.7026647,48.8030221 36.1716785,47.3875844 C36.2023931,47.347638 36.2360451,47.3092237 36.2726343,47.2726343 C36.3092237,47.2360451 36.347638,47.2023931 36.3875844,47.1716785 Z" transform="translate(-20 -31)"></path></svg>										<span*/}
                            {/*                className="toggle-text">Cerca</span>*/}
                            {/*			</span>*/}
                            {/*        </button>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default Header;
