import React, {Component} from 'react';
import {Button, Card, CardActions, CardContent, Typography} from "@mui/material";
import { withRouter } from "react-router-dom";


class DocBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id : "",
            title : "",
            main_author_name_surname : "",
            data : {},
            visible : "block"
        }
        this.state.data = this.props.data
        this.state.title = this.state.data.titolo
        this.state.id = this.state.data.id
        this.state.main_author_name_surname = this.state.data.autori[0].name.trim() + " " + this.state.data.autori[0].surname.trim()
    }

    goToDocument = (e) =>{
        if (e !== undefined) {
            e.preventDefault()
            e.stopPropagation();
        }
        this.props.history.push('/documento?id='+this.state.id)
    }


    render() {
        return (
            <Card sx={{ maxWidth: 150 }} className={"docBoxContent"} style={{display:this.state.data.visibility}} onClick={()=> this.goToDocument()}>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {this.state.main_author_name_surname}
                    </Typography>
                    <Typography variant="h5" component="div">
                        {this.state.title}
                    </Typography>
                    {/*<Typography sx={{ mb: 1.5 }} color="text.secondary">*/}
                    {/*    adjective*/}
                    {/*</Typography>*/}
                    {/*<Typography variant="body2">*/}
                    {/*    well meaning and kindly.*/}
                    {/*    <br />*/}
                    {/*    {'"a benevolent smile"'}*/}
                    {/*</Typography>*/}
                </CardContent>
                <CardActions>
                    <Button size="small" onClick={(e)=> this.goToDocument(e)}>Apri Documento</Button>
                </CardActions>
            </Card>
        );
    }
}


export default withRouter(DocBox);
