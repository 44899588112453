import React, {Component} from 'react';
import {Container} from "@mui/material";
// import SearchPanel from "../components/SearchPanel";
import DocGallery from "../components/DocGallery";


class Archivio extends Component {
    // constructor(props) {
    //     super(props);
    //
    // }

    // handleChange = name => event => {
    //     let me = this
    //     console.log(event.target.value);
    //     let val = event.target.value
    //
    //     this.setState({[name]: val}, () => {
    //         // me.performFilter()
    //         // me.windowRefresh()
    //
    //     });
    //
    // };



    render() {
        return (
            <main id="site-content" role="main" style={{fontSize: "100%"}}>
                <article className="post-2 page type-page status-publish has-post-thumbnail hentry" id="post-2" style={{padding: 0}}>
                    <div className="post-inner thin ">
                        <Container>

                            <DocGallery />
                        </Container>
                    </div>

                </article>
            </main>
        );
    }
}


export default Archivio;
