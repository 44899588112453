import React, {Component} from 'react';
import DocBox from "./DocBox";
import "../styles/DocGalleryCSS.css"
import SearchPanel from "./SearchPanel";
import axios from 'axios';
import bibliografia from "../data/bibliografia.json";


class DocGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchParams: {},
            documents: [],
            attribuzione_options: [],
            form_options: []
        }
    }

    componentDidMount() {
        let me = this
        axios.post(window.ArFLISiteUrlPrefix + 'GetDocList', {}).then(function (response) {
            let transcriptionsData = response.data
            let tempDoc = []
            let attribOpt = []
            let formOpt = []
            transcriptionsData.forEach((element) => {
                element.visibility = "flex"
                if (element.attribuzione.trim().length > 0 && !attribOpt.includes(element.attribuzione.trim()))
                    attribOpt.push(element.attribuzione.trim())
                if (element.forma.trim().length > 0 && !formOpt.includes(element.forma.trim()))
                    formOpt.push(element.forma.trim())
                tempDoc.push(element)
            })
            me.setState({documents: tempDoc, attribuzione_options: attribOpt, form_options: formOpt})
        })
        sessionStorage.removeItem('needleItems');
    }


    checkInAutor = (element, searchString) => {
        if (searchString.trim().length === 0) {
            return true
        }
        element.autori.forEach(a => {
            console.log(a.name.toLowerCase());
            console.log(a.surname.toLowerCase());
            if (a.name.toLowerCase().includes(searchString)) {

                return true
            }else if (a.surname.toLowerCase().includes(searchString)) {
                return true
            } else {
                return false
            }

        })
    }

    searchPanelChange = (searchPanelState) => {
        let me = this

        this.setState({searchParams: searchPanelState}, () => {
            let attribOpt = []
            let formOpt = []
            console.log(searchPanelState.search_transcription.toLowerCase());
            me.state.documents.forEach((d) => {

                 // console.log(d);
                let isVisible = true

                let checkAuthor = true



                if (searchPanelState.search_author.trim().length === 0) {
                    checkAuthor = true
                }else {
                    d.autori.forEach(a => {

                        if (a.name.toLowerCase().includes(searchPanelState.search_author.toLowerCase())) {

                            checkAuthor = true
                        } else if (a.surname.toLowerCase().includes(searchPanelState.search_author.toLowerCase())) {
                            checkAuthor = true
                        } else {
                            checkAuthor = false
                        }

                    })
                }
                let bibliogen = ""
                try {
                    d.bibliografia_generale.forEach(b => {
                        bibliogen += b.bibliografia_titolo + "; "
                    })
                    bibliogen = bibliogen.replaceAll(/ /g," ")
                }catch (e){

                }
                bibliografia.forEach( b =>{
                    bibliogen = bibliogen.replaceAll(b.key, '<span class="tooltip">'+b.key+'<span class="tooltiptext">'+b.value+'</span></span>')
                })

                isVisible = isVisible && d.titolo.toLowerCase().includes(searchPanelState.search_title.toLowerCase())
                isVisible = isVisible && d.attribuzione.toLowerCase().includes(searchPanelState.search_attribution.toLowerCase())
                isVisible = isVisible && d.forma.toLowerCase().includes(searchPanelState.search_form.toLowerCase())
                isVisible = isVisible && d.primi_dubbi.toLowerCase().includes(searchPanelState.search_doubt.toLowerCase())
                isVisible = isVisible && bibliogen.toLowerCase().includes(searchPanelState.search_bibliography.toLowerCase())
                isVisible = isVisible && d.rawText.toLowerCase().includes(searchPanelState.search_transcription.toLowerCase())
                isVisible = isVisible && checkAuthor

                sessionStorage.setItem("needleItems", JSON.stringify({
                    dubbi : searchPanelState.search_doubt.toLowerCase(),
                    trascription : searchPanelState.search_transcription.toLowerCase(),
                    bibliographyGen : searchPanelState.search_bibliography.toLowerCase(),
                }));



                if (isVisible) {
                    d.visibility = "flex"
                    if (d.attribuzione.trim().length > 0 && !attribOpt.includes(d.attribuzione.trim()))
                        attribOpt.push(d.attribuzione.trim())
                    if (d.forma.trim().length > 0 && !formOpt.includes(d.forma.trim()))
                        formOpt.push(d.forma.trim())
                } else {
                    d.visibility = "none"
                }
            })
            me.setState({documents: me.state.documents, attribuzione_options: attribOpt, form_options: formOpt})
        })
    }

    render() {
        return (
            <>
                <SearchPanel form_options={this.state.form_options} attribuzione_options={this.state.attribuzione_options} callbackToParent={(panelState) => this.searchPanelChange(panelState)}/>
                <div className={"docGalleryWrap"}>

                    {this.state.documents.map(value => {
                        return <DocBox data={value}/>
                    })}
                </div>
            </>
        );
    }
}


export default DocGallery;
