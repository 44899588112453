import React, {Component} from 'react';
import "../styles/wp_style.css"
import "../styles/style.css"
import {
    Route,
    Switch
} from "react-router-dom";
import routesArray from "../routesArray";
// import Background from "./elements/Background";
import Header from "./elements/Header";
import Footer from "./elements/Footer";


class DefaultLayout extends Component {
    // constructor(props) {
    //     super(props);
    //
    // }

    render() {
        return (
            <>

                <Header/>
                <Switch>
                    {routesArray.map((route, idx) => {
                            return (<Route key={idx} path={route.path} exact={route.exact} name={route.name} component={route.component} />)
                        },
                    )}
                </Switch>
                {/*</Container>*/}
                <Footer/>

            </>
        );
    }
}


export default DefaultLayout;
