import React from 'react';
import {createBrowserHistory} from "history";
import ReactDOM from 'react-dom';
import './index.css';
import DefaultLayout from "./layout/DefaultLayout";
import {
     HashRouter,
} from "react-router-dom";

const hist = createBrowserHistory();

window.ArFLISiteUrlPrefix = ""
// window.ArFLISiteUrlPrefix = "http://localhost:8080/ArFLI/"


ReactDOM.render(
  <React.StrictMode>
      <HashRouter history={hist}>
        <DefaultLayout/>
      </HashRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
