import Home from "./views/Home";
import Credits from "./views/Credits";
import Archivio from "./views/Archivio";
import Documento from "./components/Documento";


const routesArray = [
    { path: '/', name: 'Home', component: Home, exact: true },
    { path: '/Credits', name: 'Credits', component: Credits},
    { path: '/Home', name: 'Home', component: Home},
    { path: '/Archivio', name: 'Archivio', component: Archivio},
    { path: '/Documento', name: 'Documento', component: Documento},

];

export default routesArray;
