import React, {Component} from 'react';
import {Dialog, DialogTitle} from "@mui/material";

function CloseIcon() {
    return null;
}

class ImageDialog extends Component {
    constructor(props) {
        super(props);
    }

    handleClose = () => {
        this.props.handleDialogClose(true)
    }

    render() {

        return (
            <Dialog onClose={this.handleClose} open={this.props.open}>
                <img  style={{width:"60vw"}} src={'data:image/png;base64, '+this.props.img+''}/>
            </Dialog>
        );
    }
}


export default ImageDialog;
