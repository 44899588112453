import React, {Component} from 'react';
import Background from "../layout/elements/Background";

class Home extends Component {
    // constructor(props) {
    //     super(props);
    //
    // }

    render() {
        return (
            <>
                <Background/>
                <main id="site-content" role="main">
                    <article className="post-2 page type-page status-publish has-post-thumbnail hentry" id="post-2" style={{padding:0}}>
                        <div className="cover-header  bg-attachment-fixed">
                            <div className="cover-header-inner-wrapper screen-height">
                                <div className="cover-header-inner">
                                    <div className="cover-color-overlay color-accent opacity-80"></div>
                                    <header className="entry-header has-text-align-center">
                                        <div className="entry-header-inner section-inner medium">
                                            <h1 className="entry-title" style={{fontFamily:"Roboto"}}>«una menzogna è anch’essa una testimonianza»</h1>
                                            <div className="to-the-content-wrapper">
                                                <a href="#post-inner" className="to-the-content fill-children-current-color">
                                                    <svg className="svg-icon" aria-hidden="true" role="img" focusable="false" xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 22 24">
                                                        <polygon fill="%23FFF" points="721.105 856 721.105 874.315 728.083 867.313 730.204 869.41 719.59 880 709 869.41 711.074 867.313 718.076 874.315 718.076 856" transform="translate(-709 -856)"></polygon>
                                                    </svg>
                                                    <div className="screen-reader-text">Scorri verso il basso</div>
                                                </a>
                                            </div>
                                        </div>
                                    </header>
                                </div>
                            </div>
                        </div>
                        <div className="post-inner" id="post-inner" style={{backgroundColor:"rgba(250, 235, 215, 0.92)"}}>
                            <div className="entry-content">

                                <p>Cos’hanno in comune l’<em>Iscrizione ferrarese</em> e le <em>Carte d’Arborea</em>? Esistono delle costanti nelle falsificazioni prodotte in epoche diverse e da diversi falsari? Quali periodi letterari hanno conosciuto una maggiore proliferazione di falsi e quali invece hanno contribuito di più a smascherarli, favorendo la nascita di un rigoroso dibattito critico? E ancora, è possibile parlare di falsificazioni di breve, media e lunga durata? Il data-base ArFLI nasce allo scopo di censire le falsificazioni letterarie prodotte in Italia in lingua volgare, a partire da quelle attribuite ai primi secoli (XII-XV), attraverso una serie di schede che diano conto non solo degli aspetti testuali, ma anche della vita di ciascuna falsificazione nel tempo, del dibattito critico che ha saputo suscitare e delle ragioni storiche e culturali che l’hanno prodotta. Se come ha scritto Marc Bloch «una menzogna è anch’essa una testimonianza», infatti, è solo nella
                                    dimensione sistemica e in un’ottica comparativa che essa può contribuire a illuminare il fenomeno nel suo insieme; in questo senso ArFLI potrà costituire anche una piattaforma di lavoro utile per lo studio di problemi che investono in maniera trasversale i falsi di tutti i tempi.</p>

                                <p>What do the <em>Iscrizione ferrarese</em> and the <em>Carte d’Arborea</em> have in common? Are there constants in counterfeits produced in different periods and by different forgers? Which literary periods have known a greater proliferation of fakes, and which ones have contributed more to unmasking them, favoring the birth of a rigorous critical debate? Furthermore, is it possible to speak of long, middle and short duration? The database [acronimo] was born with the intention of making a census of the literary falsifications produced in Italy in the vernacular, starting with those presented by the forgers as written in the early centuries (XII-XV); it is articulated in a series of files that could account not only for textual aspects, but also for the development of each fake among the time, for the critical debate it was able to generate and its underlying historical and cultural reasons. If, according to Marc Bloch, «a lie is also a witness», it is
                                    only in the systemic dimension and from a comparative perspective that it can contribute to bring light to the whole phenomenon; from this perspective ArFLI will thus offer a helpful work-platform to study the common trends of fakes from every period.</p>

                            </div>

                        </div>
                    </article>
                </main>
            </>
        );
    }
}


export default Home;
