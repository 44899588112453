import React, {Component} from 'react';

class Credits extends Component {
    // constructor(props) {
    //     super(props);
    //
    // }

    render() {
        return (
            <main id="site-content" role="main">
                <article className="post-2 page type-page status-publish has-post-thumbnail hentry" id="post-2" style={{padding: 0}}>
                    <div className="post-inner thin ">
                        <header className="entry-header has-text-align-center header-footer-group">

                            <div className="entry-header-inner section-inner medium">

                                <h1 className="entry-title">Contatti</h1>
                            </div>


                        </header>
                        <div className="post-inner thin ">

                            <div className="entry-content">


                                <p>Arfli è un progetto <a href="https://www.lettere.unitn.it/">Dipartimento di Lettere e Filosofia</a> dell’Università di Trento, in collaborazione con la <a href="https://www.fbk.eu">Fondazione Bruno Kessler</a>.</p>


                                <p>Responsabili dell’iniziativa sono il Prof. <a href="https://webapps.unitn.it/du/it/Persona/PER0000906/Curriculum">Andrea Comboni</a> e la dott.ssa <a href="https://webapps.unitn.it/du/it/Persona/PER0048171/Didattica">Camilla Russo</a>.</p>

                            </div>
                        </div>

                    </div>
                </article>
            </main>
        );
    }
}


export default Credits;
