import React, {Component} from 'react';


class Background extends Component {

    render() {
        return (
            <div className={"bg-image"} >
            </div>
        );
    }
}


export default Background;
