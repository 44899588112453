import React, {Component} from 'react';

class Footer extends Component {

    render() {
        return (
            <footer id="site-footer" role="contentinfo" className="header-footer-group" style={{padding:"20px"}}>

                <div className="section-inner">

                    <div className="footer-credits">

                        <p className="footer-copyright">©
                            2022 <a href="/">Archivio dei Falsi Letterari Italiani (ArFLI)</a>
                        </p>


                    </div>


                    <a className="to-the-top" href="#">
						<span className="to-the-top-long">
							All'inizio <span className="arrow" aria-hidden="true">↑</span>						</span>
                        <span className="to-the-top-short">
							Su <span className="arrow" aria-hidden="true">↑</span>						</span>
                    </a>

                </div>


            </footer>
        );
    }
}


export default Footer;
